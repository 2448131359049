import React from "react";
import styled from "styled-components";
import ReactAnimationFrame from "react-animation-frame";
import { inject } from "mobx-react/index";
import { CanvasUtilities } from "../../../../utilities/CanvasUtilities";
import { szCanvas } from "../../../../stores/gameViewer/GameViewerStoreConstants";

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

// global vars
const SZ_MID_X = 117;
const PIXELS_PER_INCH_X = 70 / 17;

// CF + HH vars
const PLATE_1B_EDGE_X = 82;
const PLATE_3B_EDGE_X = 152;
const SZ_1B_BUFFER_EDGE_V2 = 78.91;
const SZ_1B_BUFFER_INNER_V2 = 85.09;
const SZ_3B_BUFFER_EDGE_V2 = 155.09;
const SZ_3B_BUFFER_INNER_V2 = 148.91;

// CF vars
const SZ_EDGE_TOP = 88;
const SZ_EDGE_BOTTOM = 172;
const PLATE_Y = 250;
const SZ_1B_BUFFER_EDGE = 73.76;
const SZ_3B_BUFFER_EDGE = 160.24;

// HH vars
const PLATE_BACK_Y = 102;
const PLATE_MID_Y = 137;
const PLATE_FRONT_Y = 172;
const SZ_1B_BUFFER_BACK_V2 = 113.91;
const SZ_3B_BUFFER_BACK_V2 = 120.09;
const SZ_INNER_BACK_V2 = 105.09;

const plateCF = [
  [PLATE_Y, PLATE_1B_EDGE_X],
  [PLATE_Y, PLATE_3B_EDGE_X]
];
const plateHH = [
  [PLATE_FRONT_Y, PLATE_1B_EDGE_X],
  [PLATE_MID_Y, PLATE_1B_EDGE_X],
  [PLATE_BACK_Y, SZ_MID_X],
  [PLATE_MID_Y, PLATE_3B_EDGE_X],
  [PLATE_FRONT_Y, PLATE_3B_EDGE_X]
];
const plateHHBuffer = [
  [PLATE_FRONT_Y, PLATE_1B_EDGE_X],
  [PLATE_FRONT_Y, SZ_1B_BUFFER_EDGE],
  [PLATE_MID_Y, SZ_1B_BUFFER_EDGE],
  [93.76, SZ_MID_X],
  [PLATE_MID_Y, SZ_3B_BUFFER_EDGE],
  [PLATE_FRONT_Y, SZ_3B_BUFFER_EDGE],
  [PLATE_FRONT_Y, PLATE_3B_EDGE_X]
];
const plateHHBufferOuter = [
  [PLATE_FRONT_Y, PLATE_1B_EDGE_X],
  [PLATE_FRONT_Y, SZ_1B_BUFFER_EDGE_V2],
  [PLATE_MID_Y, SZ_1B_BUFFER_EDGE_V2],
  [PLATE_BACK_Y, SZ_1B_BUFFER_BACK_V2],
  [PLATE_BACK_Y, SZ_3B_BUFFER_BACK_V2],
  [PLATE_MID_Y, SZ_3B_BUFFER_EDGE_V2],
  [PLATE_FRONT_Y, SZ_3B_BUFFER_EDGE_V2],
  [PLATE_FRONT_Y, PLATE_3B_EDGE_X]
];
const plateHHBufferInner = [
  [PLATE_FRONT_Y, SZ_1B_BUFFER_INNER_V2],
  [PLATE_MID_Y, SZ_1B_BUFFER_INNER_V2],
  [SZ_INNER_BACK_V2, SZ_MID_X],
  [PLATE_MID_Y, SZ_3B_BUFFER_INNER_V2],
  [PLATE_FRONT_Y, SZ_3B_BUFFER_INNER_V2]
];
const strikeZone1BBuffer = [
  [SZ_EDGE_TOP, PLATE_1B_EDGE_X],
  [SZ_EDGE_TOP, SZ_1B_BUFFER_EDGE],
  [SZ_EDGE_BOTTOM, SZ_1B_BUFFER_EDGE],
  [SZ_EDGE_BOTTOM, PLATE_1B_EDGE_X]
];
const strikeZone3BBuffer = [
  [SZ_EDGE_TOP, PLATE_3B_EDGE_X],
  [SZ_EDGE_TOP, SZ_3B_BUFFER_EDGE],
  [SZ_EDGE_BOTTOM, SZ_3B_BUFFER_EDGE],
  [SZ_EDGE_BOTTOM, PLATE_3B_EDGE_X]
];
const strikeZoneRectangularCFBufferBase = [
  [SZ_EDGE_TOP, PLATE_3B_EDGE_X],
  [SZ_EDGE_TOP, PLATE_1B_EDGE_X],
  [SZ_EDGE_BOTTOM, PLATE_1B_EDGE_X],
  [SZ_EDGE_BOTTOM, PLATE_3B_EDGE_X]
];
const pitchCircleRadius = 5.97;

class GameViewerBatterCanvas extends React.Component {
  clear() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  componentDidMount() {
    this.ctx = this.canvas.getContext("2d");

    setTimeout(this.draw(), 2000);
  }

  constructor(props) {
    super(props);

    this.onCanvasClick = this.onCanvasClick.bind(this);
    this.onCanvasMouseMove = this.onCanvasMouseMove.bind(this);
  }

  draw() {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const { cartoonAngle } = gameViewerStore;
    switch (cartoonAngle) {
      case "centerfield":
        this.drawCenterfield();
        break;
      case "high_home":
        this.drawHighHome();
        break;
      case "high_first":
        this.drawHighFirst();
        break;
      default:
        break;
    }
  }

  calculateStrikeZoneBox() {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const { pitchList, selectedPitch } = gameViewerStore;
    let width = 17;
    if (selectedPitch && selectedPitch.szWidth) {
      width = selectedPitch.szWidth;
    } else if (pitchList.length && pitchList[0] && pitchList[0].szWidth) {
      width = pitchList[0].szWidth;
    }
    return [
      [SZ_EDGE_TOP, SZ_MID_X - (1 / 2) * width * PIXELS_PER_INCH_X],
      [SZ_EDGE_BOTTOM, SZ_MID_X - (1 / 2) * width * PIXELS_PER_INCH_X],
      [SZ_EDGE_BOTTOM, SZ_MID_X + (1 / 2) * width * PIXELS_PER_INCH_X],
      [SZ_EDGE_TOP, SZ_MID_X + (1 / 2) * width * PIXELS_PER_INCH_X]
    ];
  }

  calculateCfBufferInner(bufferConfiguration, verticalScaling) {
    let buffer = JSON.parse(JSON.stringify(strikeZoneRectangularCFBufferBase));
    const horizontalBufferWidth = 0.75 * PIXELS_PER_INCH_X;
    buffer[0][0] += verticalScaling;
    buffer[0][1] -= horizontalBufferWidth;
    buffer[1][0] += verticalScaling;
    buffer[1][1] += horizontalBufferWidth;
    buffer[2][0] -= verticalScaling;
    buffer[2][1] += horizontalBufferWidth;
    buffer[3][0] -= verticalScaling;
    buffer[3][1] -= horizontalBufferWidth;
    return buffer;
  }

  calculateCfBufferOuter(bufferConfiguration, scalingAboveBottom, scalingBelowBottom) {
    let buffer = JSON.parse(JSON.stringify(strikeZoneRectangularCFBufferBase));
    const horizontalBufferWidth = 0.75 * PIXELS_PER_INCH_X;
    buffer[0][0] -= scalingAboveBottom;
    buffer[0][1] += horizontalBufferWidth;
    buffer[1][0] -= scalingAboveBottom;
    buffer[1][1] -= horizontalBufferWidth;
    buffer[2][0] += scalingBelowBottom;
    buffer[2][1] -= horizontalBufferWidth;
    buffer[3][0] += scalingBelowBottom;
    buffer[3][1] += horizontalBufferWidth;
    return buffer;
  }

  drawBackgroundCF() {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const {
      bufferConfiguration,
      selectedPitch,
      game: { absMode }
    } = gameViewerStore;
    if (bufferConfiguration && (!absMode || absMode === "noABS")) {
      if (bufferConfiguration === "V2") {
        this.drawCFRectangularBuffers(bufferConfiguration, selectedPitch);
      } else if (bufferConfiguration === "V1") {
        for (let idx = 0; idx < strikeZone1BBuffer.length - 1; idx++) {
          CanvasUtilities.drawLinePointToPoint(
            this.ctx,
            strikeZone1BBuffer[idx],
            strikeZone1BBuffer[idx + 1],
            CanvasUtilities.COLORS.RED,
            1,
            3
          );
        }
        for (let idx = 0; idx < strikeZone3BBuffer.length - 1; idx++) {
          CanvasUtilities.drawLinePointToPoint(
            this.ctx,
            strikeZone3BBuffer[idx],
            strikeZone3BBuffer[idx + 1],
            CanvasUtilities.COLORS.RED,
            1,
            3
          );
        }
        if (selectedPitch) {
          const verticalPixelsPerInch = this.getVerticalPixelsPerInch(selectedPitch);
          this.drawLowBuffer(selectedPitch, verticalPixelsPerInch);
        }
      }
    }
    let strikeZoneBox = this.calculateStrikeZoneBox();
    CanvasUtilities.drawLinePointToPoint(this.ctx, ...plateCF, CanvasUtilities.COLORS.BLACK);
    for (let idx = 0; idx < strikeZoneBox.length; idx++) {
      CanvasUtilities.drawLinePointToPoint(
        this.ctx,
        strikeZoneBox[idx],
        strikeZoneBox[(idx + 1) % strikeZoneBox.length],
        CanvasUtilities.COLORS.BLACK
      );
    }
  }

  drawBackgroundHF() {
    CanvasUtilities.drawLinePointToPoint(this.ctx, ...plateCF, CanvasUtilities.COLORS.BLACK);
    let strikeZoneBox = this.calculateStrikeZoneBox();
    let depth = null;
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const { pitchList, selectedPitch } = gameViewerStore;
    if (selectedPitch && selectedPitch.szDepth) {
      depth = selectedPitch.szDepth;
    } else if (pitchList.length && pitchList[0] && pitchList[0].szDepth) {
      depth = pitchList[0].szDepth;
    }
    if (depth) {
      let change = depth - 8.5 !== 0 ? ((depth - 8.5) / 2) * (70 / 17) : 0;
      strikeZoneBox = [
        [SZ_EDGE_TOP, SZ_MID_X - change],
        [SZ_EDGE_BOTTOM, SZ_MID_X - change]
      ];
    }
    for (let idx = 0; idx < strikeZoneBox.length; idx++) {
      CanvasUtilities.drawLinePointToPoint(
        this.ctx,
        strikeZoneBox[idx],
        strikeZoneBox[(idx + 1) % strikeZoneBox.length],
        CanvasUtilities.COLORS.BLACK
      );
    }
  }

  drawBackgroundHH() {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const {
      isMilbGame,
      game: { absMode }
    } = gameViewerStore;
    let depth = null;
    const { pitchList, selectedPitch } = gameViewerStore;
    if (selectedPitch && selectedPitch.szDepth) {
      depth = selectedPitch.szDepth;
    } else if (pitchList.length && pitchList[0] && pitchList[0].szDepth) {
      depth = pitchList[0].szDepth;
    }
    if (depth) {
      let change = depth !== 0 ? depth * (70 / 17) : 0;
      CanvasUtilities.drawLinePointToPoint(
        this.ctx,
        [PLATE_FRONT_Y - change, PLATE_1B_EDGE_X],
        [PLATE_FRONT_Y - change, PLATE_3B_EDGE_X],
        CanvasUtilities.COLORS.BLACK
      );
    } else {
      for (let idx = 0; idx < plateHH.length; idx++) {
        CanvasUtilities.drawLinePointToPoint(
          this.ctx,
          plateHH[idx],
          plateHH[(idx + 1) % plateHH.length],
          CanvasUtilities.COLORS.BLACK
        );
      }
    }
    if (!isMilbGame && !(absMode && absMode !== "noABS")) {
      this.drawHHBuffer();
    } else {
      for (let idx = 0; idx < plateHH.length - 1; idx++) {
        CanvasUtilities.drawLinePointToPoint(
          this.ctx,
          plateHH[idx],
          plateHH[(idx + 1) % plateHH.length],
          CanvasUtilities.COLORS.LIGHT_RED,
          1,
          3
        );
      }
    }
  }

  drawCenterfield() {
    const { pitchListFiltered, selectedPitch } = this.props.rootStore.gameViewerStore;
    this.drawBackgroundCF();
    if (selectedPitch) {
      this.drawPitchCF(selectedPitch, true);
    } else {
      pitchListFiltered.forEach(p => this.drawPitchCF(p, false));
    }
  }

  drawCFRectangularBuffers(bufferConfiguration, selectedPitch) {
    let scalingAboveBottom = PIXELS_PER_INCH_X;
    let scalingBelowBottom = PIXELS_PER_INCH_X;
    if (selectedPitch) {
      const szHeightInches = (selectedPitch.szTop - selectedPitch.szBottom) * 12;
      const szBottomInches = selectedPitch.szBottom * 12;
      scalingAboveBottom = (SZ_EDGE_BOTTOM - SZ_EDGE_TOP) / szHeightInches;
      scalingBelowBottom = szBottomInches / scalingBelowBottom;
    }

    let outerBuffer = this.calculateCfBufferOuter(bufferConfiguration, scalingAboveBottom, scalingBelowBottom);
    for (let idx = 0; idx < outerBuffer.length; idx++) {
      CanvasUtilities.drawLinePointToPoint(
        this.ctx,
        outerBuffer[idx],
        outerBuffer[(idx + 1) % outerBuffer.length],
        CanvasUtilities.COLORS.RED,
        1,
        3
      );
    }
    let innerBuffer = this.calculateCfBufferInner(bufferConfiguration, scalingAboveBottom);
    for (let idx = 0; idx < innerBuffer.length; idx++) {
      CanvasUtilities.drawLinePointToPoint(
        this.ctx,
        innerBuffer[idx],
        innerBuffer[(idx + 1) % innerBuffer.length],
        CanvasUtilities.COLORS.RED,
        1,
        3
      );
    }
  }

  drawHHBuffer() {
    const { bufferConfiguration } = this.props.rootStore.gameViewerStore;
    if (bufferConfiguration === "V1") {
      this.drawHHBufferV1();
    } else {
      this.drawHHBufferV2();
    }
  }

  drawHHBufferV1() {
    for (let idx = 0; idx < plateHHBuffer.length - 1; idx++) {
      CanvasUtilities.drawLinePointToPoint(
        this.ctx,
        plateHHBuffer[idx],
        plateHHBuffer[(idx + 1) % plateHHBuffer.length],
        CanvasUtilities.COLORS.RED,
        1,
        3
      );
    }
  }

  drawHHBufferV2() {
    for (let idx = 0; idx < plateHHBufferOuter.length - 1; idx++) {
      CanvasUtilities.drawLinePointToPoint(
        this.ctx,
        plateHHBufferOuter[idx],
        plateHHBufferOuter[(idx + 1) % plateHHBufferOuter.length],
        CanvasUtilities.COLORS.RED,
        1,
        3
      );
    }
    for (let idx = 0; idx < plateHHBufferInner.length - 1; idx++) {
      CanvasUtilities.drawLinePointToPoint(
        this.ctx,
        plateHHBufferInner[idx],
        plateHHBufferInner[(idx + 1) % plateHHBufferInner.length],
        CanvasUtilities.COLORS.RED,
        1,
        3
      );
    }
  }

  drawHighFirst() {
    const {
      selectedPitch,
      isMilbGame,
      bufferConfiguration,
      game: { absMode }
    } = this.props.rootStore.gameViewerStore;
    const verticalPixelsPerInch = this.getVerticalPixelsPerInch(selectedPitch);
    this.drawPitchHF();
    if (!isMilbGame && !(absMode && absMode !== "noABS")) {
      if (bufferConfiguration === "V1") {
        this.drawLowBuffer(selectedPitch, verticalPixelsPerInch);
      } else if (bufferConfiguration === "V2") {
        this.drawLowBufferV2(selectedPitch, verticalPixelsPerInch);
      }
    }
    this.drawBackgroundHF();
  }

  drawHighHome() {
    this.drawBackgroundHH();
    this.drawPitchHH();
  }

  drawLowBuffer(pitch, verticalPixelsPerInch) {
    const lowBufferPixels = verticalPixelsPerInch * 1.45;
    const lowBufferLine = this.getLowBufferLineCoordinates(lowBufferPixels);
    CanvasUtilities.drawLinePointToPoint(this.ctx, ...lowBufferLine, CanvasUtilities.COLORS.RED, 1, 3);
  }

  drawLowBufferV2(pitch, verticalPixelsPerInch) {
    const lowBufferBottom = [
      [172, 82],
      [176.12, 82],
      [176.12, 152],
      [172, 152]
    ];
    const lowBufferTop = [
      [172 - verticalPixelsPerInch, 82],
      [172 - verticalPixelsPerInch, 152]
    ];
    const highBufferTop = [
      [88, 82],
      [88 - verticalPixelsPerInch, 82],
      [88 - verticalPixelsPerInch, 152],
      [88, 152]
    ];
    const highBufferBottom = [
      [88 + verticalPixelsPerInch, 82],
      [88 + verticalPixelsPerInch, 152]
    ];
    const buffers = [lowBufferBottom, lowBufferTop, highBufferTop, highBufferBottom];
    buffers.forEach(buffer => {
      for (let idx = 0; idx < buffer.length - 1; idx++) {
        CanvasUtilities.drawLinePointToPoint(this.ctx, buffer[idx], buffer[idx + 1], CanvasUtilities.COLORS.RED, 1, 3);
      }
    });
  }

  getLowBufferLineCoordinates(lowBufferPixels) {
    let strikeZoneBox = this.calculateStrikeZoneBox();
    return [
      [strikeZoneBox[1][0] - lowBufferPixels, strikeZoneBox[1][1]],
      [strikeZoneBox[2][0] - lowBufferPixels, strikeZoneBox[2][1]]
    ];
  }

  drawPitchCF(pitch, selected) {
    const { functions, hoverPitches, pitchListFiltered } = this.props.rootStore.gameViewerStore;
    const coord = pitch.canvasPositions.centerfield;
    if (!coord) {
      return;
    }
    const color = functions.getPitchColor(pitch);
    if (pitchListFiltered.length > 1) {
      for (let idx = 0; idx < hoverPitches.length; idx++) {
        if (hoverPitches[idx].playId === pitch.playId) {
          selected = true;
        }
      }
    }
    const fillColor = selected ? color : null;
    if (coord.row < 8) {
      CanvasUtilities.drawEquilateralTriangle(this.ctx, 8, coord.col, 8, color, fillColor, 90);
    } else if (coord.row > plateCF[0][0]) {
      CanvasUtilities.drawEquilateralTriangle(this.ctx, plateCF[0][0], coord.col, 8, color, fillColor, -90);
    } else if (coord.col < 8) {
      CanvasUtilities.drawEquilateralTriangle(this.ctx, plateCF[0][0], coord.col, 8, color, fillColor, 0);
    } else if (coord.col > 227) {
      CanvasUtilities.drawEquilateralTriangle(this.ctx, plateCF[0][0], coord.col, 8, color, fillColor, 180);
    } else {
      CanvasUtilities.drawCircle(this.ctx, coord.row, coord.col, pitchCircleRadius, color, fillColor);
    }
  }

  drawPitchHF() {
    const { functions, selectedPitch } = this.props.rootStore.gameViewerStore;
    const coords = selectedPitch.canvasPositions.highFirst;
    if (!coords || coords.length !== 2) {
      return;
    }
    const color = functions.getPitchColor(selectedPitch);
    CanvasUtilities.drawLinePointToPointRowCol(this.ctx, ...coords, color, pitchCircleRadius * 2);
  }

  drawPitchHH() {
    const { functions, selectedPitch } = this.props.rootStore.gameViewerStore;
    const coords = selectedPitch.canvasPositions.highHome;
    if (!coords || coords.length !== 2) {
      return;
    }
    const color = functions.getPitchColor(selectedPitch);
    CanvasUtilities.drawLinePointToPointRowCol(this.ctx, ...coords, color, pitchCircleRadius * 2);
  }

  getVerticalPixelsPerInch(pitch) {
    let strikeZoneBox = this.calculateStrikeZoneBox();
    if (!pitch) {
      return (strikeZoneBox[1][0] - strikeZoneBox[0][0]) / 18;
    }
    const { szTop, szBottom } = pitch;
    const szHeightInInches = szTop && szBottom ? (szTop - szBottom) * 12 : 18;
    return (strikeZoneBox[1][0] - strikeZoneBox[0][0]) / szHeightInInches;
  }

  onAnimationFrame(time) {
    if (this.ctx) {
      this.clear();
      this.draw();
    }
  }

  onCanvasClick(event) {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const { cartoonAngle, hoverPitches, selectedPitch } = gameViewerStore;
    if (cartoonAngle !== "centerfield") {
      return;
    }
    if (!hoverPitches.length) {
      return;
    }
    if (selectedPitch) {
      hoverPitches.forEach(hp => {
        if (hp.playId === selectedPitch.playId) {
          gameViewerStore.setSelectedPitch(null);
        }
      });
    } else if (hoverPitches.length === 1) {
      gameViewerStore.setSelectedPitch(hoverPitches[0], true);
    } else if (hoverPitches.length > 1) {
      gameViewerStore.setPitchListFilter(hoverPitches.map(hp => hp.pitchNumber).join(","));
    }
  }

  onCanvasMouseMove(event) {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const { pitchListFiltered } = gameViewerStore;
    const { x, y } = event.currentTarget.getBoundingClientRect();
    const { clientX, clientY } = event;
    let canvasPosition = {
      row: clientY - y,
      col: clientX - x
    };
    let hoverPitches = [];
    pitchListFiltered.forEach(p => {
      const distance = CanvasUtilities.distanceBetweenPoints(p.canvasPositions.centerfield, canvasPosition);
      if (distance > -1 && distance < szCanvas.pitchCircleRadius) {
        hoverPitches.push(p);
      }
    });
    gameViewerStore.setHoverPitches(hoverPitches);
  }

  render() {
    return (
      <Canvas
        {...this.props}
        innerRef={c => {
          this.canvas = c;
        }}
        {...{
          onClick: this.onCanvasClick,
          onMouseMove: this.onCanvasMouseMove
        }}
      />
    );
  }
}

export default inject("rootStore")(ReactAnimationFrame(GameViewerBatterCanvas));
