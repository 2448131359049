import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import GameViewerFilterCallCorrectness from "./GameViewerFilterCallCorrectness";
import GameViewerFilterCalled from "./GameViewerFilterCalled";
import GameViewFilterTextBox from "./GameViewFilterTextBox";

const CalledFilter = styled.div.attrs({
  className: "d-flex flex-row justify-content-between mt-1"
})`
  width: 235px;
`;

const CorrectnessFilter = styled.div.attrs({
  className: "d-flex flex-row justify-content-left"
})`
  border-left: 1px solid #d2d2d2;
  width: 235px;
`;

const ResetFilter = styled.a`
  color: #2b77eb !important;
  cursor: pointer;
  font-size: 12px;
  height: 14px;
`;

const ResetFilterHolder = styled.div.attrs({
  className: "d-flex flex-row justify-content-between mt-2"
})``;

const TextFilter = styled.div.attrs({
  className: "d-flex flex-row mt-1"
})``;

const GameViewerCallFilters = ({ rootStore: { gameViewerStore } }) => {
  const { calledFilter, correctnessFilter, functions, hasEdgeFilter, isMilbGame, game } = gameViewerStore;
  const { absMode } = game;

  return (
    <div className="flex-column">
      <CorrectnessFilter>
        <GameViewerFilterCallCorrectness label="Incorrect" value={correctnessFilter.incorrect} />
        {!isMilbGame && absMode && absMode !== "noABS" ? (
          <GameViewerFilterCallCorrectness label="Acceptable" value={correctnessFilter.acceptable} />
        ) : null}
        <GameViewerFilterCallCorrectness label="Correct" value={correctnessFilter.correct} />
        {hasEdgeFilter ? <GameViewerFilterCallCorrectness label="Edge" value={correctnessFilter.edge} /> : null}
      </CorrectnessFilter>
      <CalledFilter>
        <GameViewerFilterCalled label="All" value="all" selected={calledFilter === "all"} />
        <GameViewerFilterCalled label="Called" value="called" selected={calledFilter === "called"} />
        {absMode ? (
          <>
            <GameViewerFilterCalled label="Challenged" value="challenged" selected={calledFilter === "challenged"} />
            <GameViewerFilterCalled label="Feedback" value="feedback" selected={calledFilter === "feedback"} />
          </>
        ) : null}
      </CalledFilter>
      <TextFilter>
        <GameViewFilterTextBox />
      </TextFilter>
      <ResetFilterHolder>
        <ResetFilter onClick={functions.resetFilters.bind(gameViewerStore)}>reset filters</ResetFilter>
      </ResetFilterHolder>
    </div>
  );
};

export default inject("rootStore")(observer(GameViewerCallFilters));
