import { action, computed, extendObservable } from "mobx";
import { AuthConstants } from "../components/login/AuthConstants";
import ReactGA from "react-ga";

class AuthStore {
  constructor(routerStore, authApi) {
    this.routerStore = routerStore;
    this.authApi = authApi;
    this.defaults = {
      name: "",
      username: "",
      authorities: [],
      umpireId: ""
    };

    extendObservable(this, {
      name: this.defaults["name"],
      username: this.defaults["username"],
      authorities: this.defaults["authorities"],
      umpireId: this.defaults["umpireId"],
      setAuthorities: action(value => {
        this.authorities = value;
      }),
      setZEUser: action(value => {
        this.username = value.username ? value.username : this.defaults["username"];
        this.name = value.name ? value.name : this.defaults["name"];
        this.umpireId = value.umpireId ? value.umpireId : this.defaults["umpireId"];
        this.authorities = value.authorities ? value.authorities : this.defaults["authorities"];
        ReactGA.set({
          userId: this.username
        });
      }),
      checkLocalStorage: action(() => {
        this.username = localStorage.getItem(AuthConstants.KEYS.USERNAME) || "";
        this.name = localStorage.getItem(AuthConstants.KEYS.NAME) || "";
        this.umpireId = localStorage.getItem(AuthConstants.KEYS.UMPIRE_ID) || "";
        this.authorities = localStorage.getItem(AuthConstants.KEYS.AUTHORITIES)
          ? JSON.parse(localStorage.getItem(AuthConstants.KEYS.AUTHORITIES))
          : [];
        if (this.authorities.length && typeof this.authorities[0] === "object") {
          localStorage.clear();
          window.location.reload();
        }
        if (!this.username || this.username.length === 0 || this.username === "undefined") {
          this.getUserInfo();
        }
      }),
      setLocalStorage: action(() => {
        localStorage.setItem(AuthConstants.KEYS.USERNAME, this.username);
        localStorage.setItem(AuthConstants.KEYS.NAME, this.name);
        localStorage.setItem(AuthConstants.KEYS.UMPIRE_ID, this.umpireId);
        let authorities = this.authorities ? JSON.stringify(this.authorities) : "[]";
        localStorage.setItem(AuthConstants.KEYS.AUTHORITIES, authorities);
      }),
      resetStore: action(() => {
        this.name = this.defaults["name"];
        this.username = this.defaults["username"];
        this.authorities = this.defaults["authorities"];
        this.umpireId = this.defaults["umpireId"];
      }),
      logout: action(() => {
        localStorage.clear();
        this.resetStore();
        window.location.href = window.location.origin + "/v2";
      }),
      getUserInfo: action(() => {
        this.authApi.getUserInfo().then(data => {
          if (data) {
            this.setZEUser(data);
            this.setLocalStorage();
          }
        });
      }),
      isLoggedIn: computed(() => {
        return !this.routerStore.isLoginTab && this.authorities.length;
      }),
      isAdmin: computed(() => {
        if (!this.authorities || this.authorities.length === 0) {
          return false;
        }
        return this.containsAuthorities([
          AuthConstants.USER_ROLES.ZE_BOC_ADMIN,
          AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN,
          AuthConstants.USER_ROLES.ZE_SUPER_ADMIN
        ]);
      }),
      isAuditor: computed(() => {
        if (!this.authorities || this.authorities.length === 0) {
          return false;
        }
        return this.containsAuthorities([
          AuthConstants.USER_ROLES.ZE_AUDITOR,
          AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN,
          AuthConstants.USER_ROLES.ZE_SUPER_ADMIN
        ]);
      }),
      isAuditorAdmin: computed(() => {
        if (!this.authorities || this.authorities.length === 0) {
          return false;
        }
        return this.containsAuthorities([
          AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN,
          AuthConstants.USER_ROLES.ZE_SUPER_ADMIN
        ]);
      }),
      isDisputesAdmin: computed(() => {
        if (!this.authorities || this.authorities.length === 0) {
          return false;
        }
        return this.containsAuthorities([
          AuthConstants.USER_ROLES.ZE_BOC_ADMIN,
          AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN,
          AuthConstants.USER_ROLES.ZE_SUPER_ADMIN,
          AuthConstants.USER_ROLES.ZE_UMPIRE_SUPERVISOR
        ]);
      }),
      isDisputesResolver: computed(() => {
        if (!this.authorities || this.authorities.length === 0) {
          return false;
        }
        return this.containsAuthorities([
          AuthConstants.USER_ROLES.ZE_BOC_ADMIN,
          AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN,
          AuthConstants.USER_ROLES.ZE_SUPER_ADMIN
        ]);
      }),
      isAnyRole: computed(() => {
        return this.authorities && this.authorities.length > 0;
      }),
      isSuperUmpire: computed(() => {
        if (!this.authorities || this.authorities.length === 0) {
          return false;
        }
        return this.containsAuthorities([AuthConstants.USER_ROLES.ZE_SUPER_UMPIRE]);
      }),
      isSuperAdmin: computed(() => {
        if (!this.authorities || this.authorities.length === 0) {
          return false;
        }
        return this.containsAuthorities([AuthConstants.USER_ROLES.ZE_SUPER_ADMIN]);
      }),
      isRegularUmpire: computed(() => {
        if (!this.authorities || this.authorities.length === 0) {
          return false;
        }
        return this.containsAuthorities([AuthConstants.USER_ROLES.ZE_UMPIRE]);
      }),
      isUmpire: computed(() => {
        if (!this.authorities || this.authorities.length === 0) {
          return false;
        }
        return this.containsAuthorities([
          AuthConstants.USER_ROLES.ZE_SUPER_UMPIRE,
          AuthConstants.USER_ROLES.ZE_UMPIRE,
          AuthConstants.USER_ROLES.ZE_MINOR_UMPIRE,
          AuthConstants.USER_ROLES.ZE_UMPIRE_CALLUP
        ]);
      }),
      isUmpireSupervisor: computed(() => {
        if (!this.authorities || this.authorities.length === 0) {
          return false;
        }
        return this.containsAuthorities([AuthConstants.USER_ROLES.ZE_UMPIRE_SUPERVISOR]);
      }),
      isAuditorNonAdmin: computed(() => {
        return this.containsAuthorities([AuthConstants.USER_ROLES.ZE_AUDITOR]);
      }),
      containsAuthorities: authorities => {
        let contains = false;
        if (this.authorities) {
          authorities.forEach(authority => {
            if (this.authorities.findIndex(a => a && authority && a.toUpperCase() === authority.toUpperCase()) >= 0) {
              contains = true;
            }
          });
        }
        return contains;
      }
    });
  }

  serialize(obj) {
    let str = [];
    for (let p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }
}

export default AuthStore;
